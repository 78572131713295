




























































































































import {
  Vue, Component, Watch,
} from 'vue-property-decorator';
import { User, Post } from '@/types';

@Component
export default class MyNewsDialog extends Vue {
  dialog = false
  loading = false
  valid = false

  get value(): boolean {
    return this.$store.getters.myNewsDialog;
  }

  get currentUser(): User {
    return this.$store.getters.user;
  }

  get isLoggedIn(): boolean {
    return this.currentUser.loggedIn;
  }

  get posts(): Post[] {
    return this.$store.getters.posts;
  }

  get postsCount(): number {
    return this.posts.length;
  }

  @Watch('value')
  onValueChanged(val: boolean) {
    console.log(`${this.constructor.name}: onValueChanged()`); // eslint-disable-line no-console

    if (val) {
      this.loading = false;
    }

    this.dialog = val;
  }

  @Watch('dialog')
  onDialogChanged(val: boolean) {
    console.log(`${this.constructor.name}: onDialogChanged()`); // eslint-disable-line no-console

    if (this.value !== val) {
      this.$store.dispatch('toggleDialog', 'myNews');
    }

    // Scrolla in cima alla finestra
    // Questo metodo funziona solo per le dialog scrollabili
    if (val) {
      this.$nextTick(() => {
        const element = document.querySelector('.v-dialog--active > .v-card > .v-card__text');
        if (element) this.$vuetify.goTo(0, { container: element as HTMLElement, duration: 0 });
      });
    }
  }

  onLogin() {
    console.log(`${this.constructor.name}: onLogin()`); // eslint-disable-line no-console

    this.dialog = false;
    this.$store.dispatch('openDialog', 'login');
  }

  onCancel() {
    this.dialog = false;
  }
}
